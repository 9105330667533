import { getMDXComponent } from 'mdx-bundler/client/index.js'
import * as React from 'react'
import { type MetaFunction, useLoaderData, useLocation } from 'react-router'
import { Card, CardContent, CardHeader } from '#app/components/ui/card'
import { Icon } from '#app/components/ui/icon'
import { type loader } from './__mdx.server'

export default function DocumentComponent() {
	const location = useLocation()
	React.useEffect(() => {
		if (location.hash) {
			const el = document.querySelector(location.hash)
			if (el) el.scrollIntoView()
		}
	}, [location])

	const data = useLoaderData<typeof loader>()
	const { mdx, timeAgo } = data
	const Component = React.useMemo(() => getMDXComponent(mdx), [mdx])

	return (
		<Card className="container mb-6 rounded-none bg-muted px-0 pb-12 xl:rounded-3xl">
			<CardHeader className="mx-0">
				<div className="flex w-full items-center justify-between gap-2 p-4 pb-0 pl-5">
					<span className="text-sm text-foreground/90 max-md:hidden">
						<Icon name="clock" className="scale-125">
							Last Modified {timeAgo} ago
						</Icon>
					</span>
				</div>
			</CardHeader>
			<CardContent className="p-10 pt-6">
				<div className="prose prose-zinc max-w-none dark:prose-invert lg:prose-lg">
					<Component />
				</div>
			</CardContent>
		</Card>
	)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: `${data?.document.title} | Clearwater Farms 1` },
		{
			name: 'description',
			content: `${data?.document.title} | Clearwater Farms 1`,
		},
	]
}
